import React, { useState, useCallback } from "react";
import SyncLoader from "react-spinners/SyncLoader";
import { cities, province } from "../cities";
import toast from "react-hot-toast";

const Button = ({ onClick, children, disabled, backgroundColor="#0ea5e9" }) => (
  <button className="rounded" style={{
    height:"50px",
    background: backgroundColor,
  }} onClick={onClick} disabled={disabled}>
    {children}
  </button>
);

const Input = ({ id, type, placeholder, value, onChange }) => (
  <div className="col-md-6 mb-4">
    <div className="d-flex flex-column gap-1">
      <label htmlFor={id} className="text-warning">
        {placeholder}:
      </label>
      <input
      style={{height: "60px", borderRadius:"8px", border:"none", padding:"2px 6px"}}
        id={id}
        required
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-100"
      />
    </div>
  </div>
);

const FileInput = ({ id, placeholder, onChange }) => (
  <div className="col-md-6 d-flex gap-1 flex-column">
    <label htmlFor={id} className="text-warning">
      {placeholder}:
    </label>
    <input
    required
      className="form-control form-control-lg bg-light text-dark"
      id={id}
      onChange={onChange}
      type="file"
      accept="image/*"
    />

    {/* <input id={id} type="file" onChange={onChange} className="input" /> */}
    {/* {image && <img src={image} alt={placeholder} className="image" />} */}
  </div>
);

const CarForm = ({ onSave, APIimage1, APIimage2, APIimage3, APIimage4, carModals }) => {
  const [fields, setFields] = useState({
    company_id: "",
    car_name: "",
    color: "",
    fuelType: "",
    mileage: "",
    engine: "",
    kmsDriven: "",
    address: "",
    famousPlace: "",
    pricePerDay: "",
    city: "",
    phone: "",
    province: "",
    company_name:"",
    latitude: null,
    longitude: null,
    image1:null,
    image2:null,
    image3:null,
    image4:null,
  });

  const [gettingLocation, setGettingLocation] = useState(false);

  const handleInputChange = (field) => (event) => {

    if (field === "company") {
      let company = carModals.find((company) => company.model_id === event.target.value);
      setFields({
        ...fields,
        company_id: company.model_id,
        company_name: company.model_name
      });
      return;
    }

    setFields({ ...fields, [field]: event.target.value });
  };

  const handleImageChange = (field) => (event) => {
    const file = event.target.files[0];

    if (field === "image1") {
      APIimage1(file);
    } else if (field === "image2") {
      APIimage2(file);
    } else if (field === "image3") {
      APIimage3(file);
    } else if (field === "image4") {
      APIimage4(file);
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFields({ ...fields, [field]: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!validateForm()){
      return
    }
    onSave(fields);
  };

  const fetchLocation = (event) => {
    event.preventDefault();
    setGettingLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          toast.success(
            `Location Saved latitude: ${latitude}, longitude: ${longitude}`
          );
          setFields({ ...fields, longitude: longitude, latitude: latitude });
          setGettingLocation(false);
        },
        (error) => {
          toast.error("Error fetching location", error);
          setGettingLocation(false);
        }
      );
    }
    setGettingLocation(false);
  };

  const validateForm = useCallback(() => {
    
    for (const field of Object.keys(fields)) {
      if (!fields[field]) {
        toast.error(`${field} is missing`)
        return false;
      }
    }
    return true;
  }, [fields]);

  // useEffect(() => {
  //   setFormValid(validateForm());
  // }, [fields, validateForm]);

  return (
    <>
      <div
        className="container mt-5 p-4"
        style={{ backgroundColor: "#f3f4f6" }}
      >
        <h1 className="text-warning my-3 ">Add Car for Booking</h1>
        <form className="" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 d-flex flex-column gap-1">
              <label className="text-warning" htmlFor="company">
                Company:
              </label>
              <select
                id="company"
                required
                className="w-100 select-form"
                value={fields.company_id}
                onChange={handleInputChange("company")}
              >
                <option value="" disabled>
                  Select a company
                </option>
                {carModals?.map((company) => (
                  <option key={company.model_id} value={company.model_id}>
                    {company.model_name}
                  </option>
                ))}
              </select>
            </div>
            <Input
              id="car_name"
              type="text"
              placeholder="Car Name"
              value={fields.car_name}
              onChange={handleInputChange("car_name")}
            />
            <Input
              id="color"
              type="text"
              placeholder="Color"
              value={fields.color}
              onChange={handleInputChange("color")}
            />
            <Input
              id="mileage"
              type="text"
              placeholder="Mileage"
              value={fields.mileage}
              onChange={handleInputChange("mileage")}
            />
            <Input
              id="fuelType"
              type="text"
              placeholder="Fuel Type"
              value={fields.fuelType}
              onChange={handleInputChange("fuelType")}
            />

            <Input
              id="engine"
              type="text"
              placeholder="Engine"
              value={fields.engine}
              onChange={handleInputChange("engine")}
            />
            <Input
              id="kmsDriven"
              type="text"
              placeholder="Kms Driven"
              value={fields.kmsDriven}
              onChange={handleInputChange("kmsDriven")}
            />
            <Input
              id="pricePerDay"
              type="text"
              placeholder="Price Per Day"
              value={fields.pricePerDay}
              onChange={handleInputChange("pricePerDay")}
            />
          
            <div className="col-md-6 d-flex flex-column gap-1">
              <label className="text-warning" htmlFor="province">
                Province:
              </label>
              <select
                id="province"
                required
                className="w-100 select-form"
                value={fields.province}
                onChange={handleInputChange("province")}
              >
                <option value="" disabled>
                  Select a Province
                </option>
                {province?.map((pro, i) => (
                  <option key={i} value={pro}>
                    {pro}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6 d-flex flex-column gap-1">
              <label className="text-warning" htmlFor="city">
                City:
              </label>
              <select
                id="city"
                required
                className="w-100 select-form"
                value={fields.city}
                onChange={handleInputChange("city")}
              >
                  {fields.province === "" ? (
                    <option value="" disabled>
                      Select a Province First
                    </option>
                  ) : (
                    <>
                      <option value="" disabled>
                        Select a City
                      </option>
                      {cities[fields.province]?.map((city, i) => (
                        <option key={i} value={city}>
                          {city}
                        </option>
                      ))}
                    </>
                  )}

              </select>
            </div>
            <Input
              id="address"
              type="text"
              placeholder="Address"
              value={fields.address}
              onChange={handleInputChange("address")}
            />
           
            <Input
              id="famousPlace"
              type="text"
              placeholder="Famous Place"
              value={fields.famousPlace}
              onChange={handleInputChange("famousPlace")}
            />
            <Input
              id="phone"
              type="tel"
              placeholder="Phone"
              value={fields.phone}
              onChange={handleInputChange("phone")}
            />
          </div>
          <div className="row">
            <FileInput
              id="image1"
              placeholder="Image 1"
              onChange={handleImageChange("image1")}
              // image={fields.image1}
            />
            <FileInput
              id="image2"
              placeholder="Image 2"
              onChange={handleImageChange("image2")}
              // image={fields.image2}
            />
            <FileInput
              id="image3"
              placeholder="Image 3"
              onChange={handleImageChange("image3")}
              // image={fields.image3}
            />
            <FileInput
              id="image4"
              placeholder="Image 4"
              onChange={handleImageChange("image4")}
              image={fields.image4}
            />
          </div>

          <div className="row my-4 ">
            <Button onClick={fetchLocation}>
              {gettingLocation ? (
                <SyncLoader color="#0ea5e9" />
              ) : (
                "Get Location"
              )}
            </Button>
          </div>
          <div className="row">
            <Button
              type="submit"
              // style={{ marginTop: "20px" }}
              backgroundColor="#ffc107"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
      <div className="sb sb-1">
        <hr className="section-break-1" />
      </div>
    </>
  );
};

export default CarForm;
