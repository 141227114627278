// SignUpPageCustomer.jsx

import React, { useEffect, useState } from "react";
import backgroundImage from "./black car 2(mirror).jpg";
import { useNavigate, useParams } from "react-router-dom";
import AuthAPIs from "../APIs/auth";
import SyncLoader from "react-spinners/SyncLoader";
import toast from "react-hot-toast";

function SignUpPageCustomer() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [signupForm, setSignupForm] = useState({
    name: "",
    email: "",
    password: "",
    profileImage: null,
    idFrontImage: null,
    idBackImage: null,
    subscriptionPlan: "",
  });
  let { type } = useParams();
  const [isRenter, setIsRenter] = useState();

  useEffect(() => {
    if (type === "renter") {
      setIsRenter(true);
    } else {
      setIsRenter(false);
    }
  }, [type]);

  const handleSignupChange = (e) => {
    if (e.target.type === "file") {
      setSignupForm({ ...signupForm, [e.target.name]: e.target.files[0] });
    } else {
      setSignupForm({ ...signupForm, [e.target.name]: e.target.value });
    }
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", signupForm.name);
      formData.append("email", signupForm.email);
      formData.append("password", signupForm.password);
      formData.append("profileImage", signupForm.profileImage);
      formData.append("idFrontImage", signupForm.idFrontImage);
      formData.append("idBackImage", signupForm.idBackImage);
      if (signupForm.subscriptionPlan !== "") {
        formData.append("subscriptionPlan", signupForm.subscriptionPlan);
      }
      setIsLoading(true);
      const response = await AuthAPIs.register(formData);
      if (response) {
        toast.success("User Registered Successfully")
        setInterval(()=>{
          navigate("/login")
        })
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Email Already Exist");
    }
    setIsLoading(false);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // background: "#232323",
        // color: "#fff",
        minHeight: "100vh",
      }}
    >
      <style>
        {`
        /* CSS styles */
        .login-signup-container {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        .form-container {
        margin:10px 0;
          padding: 0 40px;
          text-align: center;
          background: #ffffff;
          box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);

        }

        .image-container-auth {
          display:flex;
          width: 50%;
        }

        .image-container-auth img {
          width: 650px;
          object-fit: cover;
        }

        .form {
          padding: 25px;
          height: 100%; /* Set height to 100% */
          overflow-y: auto; /* Set overflow to scroll */
        }

        .form label {
          display: block;
          // margin-bottom: 5px;
          // font-weight: bold;
          text-align: left;
          color: #000;
        }

        .form input, .form select {
          width: calc(100% - 20px);
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #d9dee3;
        }

        .form button {
          width: calc(100% - 20px);
          padding: 10px;
        }

        .form button:hover {
          background-color: #ffc107;
        }

        .form p {
          font-size: 15px;
          color: #000;
        }

        .form .login-btn {
          background: none;
          border: none;
          color: #ffc107;
          cursor: pointer;
          font-size: 16px;
        }

        .form .login-btn:hover {
          color: black;
        }

        h2 {
          font-size: 30px;
          font-weight: bold;
          color: #ffc107;
          margin-bottom: 20px;
          text-align: center;
        }

        .error-message {
          color: red;
          margin-top: 10px;
        }

        .success-message {
          color: green;
          margin-top: 10px;
          animation: fadeOut 2s ease-in-out; /* Animation for fading out */
        }

        @keyframes fadeOut {
          0% { opacity: 1; }
          50% { opacity: 0.5; }
          100% { opacity: 0; }
        }
      `}
      </style>
      <div className="login-signup-container">
        <div className="form-container">
          <h2>SignUp As {capitalizeFirstLetter(type)}</h2>
          <div className="form">
            <form onSubmit={handleSignupSubmit}>
              <label>
                Full Name:
                <input
                  type="text"
                  name="name"
                  required
                  value={signupForm.name}
                  onChange={handleSignupChange}
                />
              </label>
              <br />
              <label>
                Email:
                <input
                  required
                  type="email"
                  name="email"
                  value={signupForm.email}
                  onChange={handleSignupChange}
                />
              </label>
              <br />
              <label>
                Password:
                <input
                  required
                  type="password"
                  name="password"
                  value={signupForm.password}
                  onChange={handleSignupChange}
                />
              </label>
              <br />
              <label>
                Profile photo:
                <input
                  required
                  type="file"
                  name="profileImage"
                  onChange={handleSignupChange}
                />
              </label>
              <br />
              <label>
                Front side of ID card:
                <input
                  required
                  type="file"
                  name="idFrontImage"
                  onChange={handleSignupChange}
                />
              </label>
              <br />
              <label>
                Backside of ID card:
                <input
                  required
                  type="file"
                  name="idBackImage"
                  onChange={handleSignupChange}
                />
              </label>
              <br />
              {isRenter && (
                <label>
                  Subscription plan:
                  <select
                    name="subscriptionPlan"
                    value={signupForm.subscriptionPlan}
                    onChange={handleSignupChange}
                  >
                    <option value="">--Select Plan--</option>
                    <option value="basic">Basic</option>
                  </select>
                </label>
              )}
              <br />
              {isLoading ? (
                <div style={{ marginLeft: "80px", marginTop: "30px" }}>
                  <SyncLoader color="#ffff01" />
                </div>
              ) : (
                <button
                  disabled={isLoading}
                  type="submit"
                  className="btn btn-primary"
                >
                  Sign Up
                </button>
              )}

              
            </form>
            <p>Already have an account? </p>
            <button
              className="login-btn"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
          </div>
        </div>
        <div className="image-container-auth">
          <img src={backgroundImage} alt="Background" />
        </div>
      </div>
    </div>
  );
}

export default SignUpPageCustomer;
