import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import BrowserRouter and useNavigate
import backgroundImage from "./black car 2(mirror).jpg";
import axios from "axios";

function LoginPage() {
  const [errorMessage, setErrorMessage] = useState("");
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const [loginSuccess, setLoginSuccess] = useState(false);
  const navigate = useNavigate();
  const handleLoginChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("email", loginForm.email);
      formData.append("password", loginForm.password);

      const response = await axios.post(
        "https://skenderneziraj.com/api/user/login.php",
        formData
      );
      if (response) {
        localStorage.setItem("user", JSON.stringify(response?.data?.data));
        localStorage.setItem("token", JSON.stringify(response?.data?.token));
        setLoginSuccess(true);
        if (response?.data?.data?.subscriptionPlan === "") {
          navigate("/customer-dashboard");
        } else {
          navigate("/renter-dashboard");
        }
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setErrorMessage("Invalid email or password");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        marginTop: "20px",
        // background: "#232323",
        // color: "#fff",
        // minHeight: "100vh",
      }}
    >
      <style>
        {`
          /* CSS styles */
          .login-signup-container {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          
          .form-container {
            padding: 0 40px;
            text-align: center;
            background: #ffffff;
            box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);

          }

          .image-container-auth {
            display:flex;
            width: 50%;
          }
          .image-container-auth {
            // width: 51%; 
            // height: 60%; 
            // display: flex; 
            // justify-content: start; 
            // align-items: center; 
            // overflow: hidden; 
            // border-top-left-radius: 20px; 
            // border-bottom-left-radius: 20px; 
            // border: 1px solid #232323; 
            // background-color: #232323;
            // padding-top: 75px;
                      
          }

          .image-container-auth img {
            width: 339px;
            object-fit: cover;
          }

          .form {
            padding: 25px;
            height: 100%; /* Set height to 100% */
            overflow-y: auto; /* Set overflow to scroll */
          }

          .form label {
            display: block;
            // margin-bottom: 5px;
            // font-weight: bold;
            text-align: left;
            color: #000;
          }

          .form input, .form select {
            width: calc(100% - 20px);
            padding: 10px;
            // margin-bottom: 5px;
            // border: 1px solid #fff;
            border-radius: 5px;
            border: 1px solid #d9dee3;
          }

          .form button {
            width: calc(100% - 20px);
            padding: 10px;
            // background-color: #FFFF00;
            // color: black;
            // border: none;
            // cursor: pointer;
          }

          .form button:hover {
            background-color: #ffc107;
          }

          .form p {
            // margin-top: 16px;
            font-size: 15px;
            color: #000;
          }

          .form .signup-btn {
            background: none;
            border: none;
            color: #ffc107;
            cursor: pointer;
            font-size: 16px;
          }

          .form .signup-btn:hover {
            // text-decoration: none;
            color: black;
          }

          h2 {
            font-size: 30px;
            font-weight: bold;
            color: #FFD059;
            margin-bottom: 20px;
            text-align: center;
          }

          .error-message {
            color: red;
            margin-top: 10px;
          }

          .success-message {
            color: green;
            margin-top: 10px;
            animation: fadeOut 2s ease-in-out; /* Animation for fading out */
          }

          @keyframes fadeOut {
            0% { opacity: 1; }
            50% { opacity: 0.5; }
            100% { opacity: 0; }
          }
        `}
      </style>
      <div className="login-signup-container">
        <div className="form-container">
          <h2>Login</h2>
          <div className="form">
            <form onSubmit={handleLoginSubmit}>
              <label>
                Email:
                <input
                required
                  type="email"
                  name="email"
                  value={loginForm.email}
                  onChange={handleLoginChange}
                />
              </label>
              <br />
              <label>
                Password:
                <input
                required
                  type="password"
                  name="password"
                  value={loginForm.password}
                  onChange={handleLoginChange}
                />
              </label>
              <br />
              <button type="submit" className=" btn btn-primary" x>
                Login
              </button>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              {loginSuccess && (
                <p className="success-message">Login Successful</p>
              )}
            </form>
            <p>Don't have an account? </p>
            <button
              className="signup-btn "
              onClick={() => {
                navigate("/signup_type");
              }}
            >
              Sign up
            </button>
          </div>
        </div>
        <div className="image-container-auth">
          <img src={backgroundImage} alt="Background" />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
