import React from "react";

const info = {
  phone: "+38 349490099",
  email: "Skenderneziraj@gmail.com",
  address: "Skender Neziraj, Rr. Zeqë Mehmeti, 23000 Greikoc, Kosovo",
};

const Contact = () => {
  return (
    <section className="contact-us py-5">
      <div className="container">
        <div className="text-center">
          <h1>Contact Us.</h1>
          <p className="text-dark">Feel free to get quote.</p>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="down-contact">
              <div className="row">
                <div className="col-lg-8">
                  <div className="sidebar-item contact-form">
                    <div className="sidebar-heading">
                      <h2>Send us a message</h2>
                    </div>
                    <div className="content">
                      <form id="contact" action="" method="post">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <fieldset>
                              <input
                                name="name"
                                type="text"
                                id="name"
                                placeholder="Your name"
                                required=""
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <fieldset>
                              <input
                                name="email"
                                type="text"
                                id="email"
                                placeholder="Your email"
                                required=""
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-12 col-sm-12">
                            <fieldset>
                              <input
                                name="subject"
                                type="text"
                                id="subject"
                                placeholder="Subject"
                              />
                            </fieldset>
                          </div>
                          <div className="col-lg-12">
                            <fieldset>
                              <textarea
                                name="message"
                                rows="6"
                                id="message"
                                placeholder="Your Message"
                                required=""
                              ></textarea>
                            </fieldset>
                          </div>
                          <div className="col-lg-12">
                            <fieldset>
                              <button
                                type="submit"
                                id="form-submit"
                                className="btn btn-warning text-nowrap mb-2 w-100 text-dark"
                              >
                                Send Message
                              </button>
                            </fieldset>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="sidebar-item contact-information">
                    <div className="sidebar-heading">
                      <h2>Contact Information</h2>
                    </div>
                    <div className="content">
                      <ul>
                        <li>
                          <h5>{info.phone}</h5>
                          <span>PHONE NUMBER</span>
                        </li>
                        <li>
                          <h5>{info.email}</h5>
                          <span>EMAIL ADDRESS</span>
                        </li>
                        <li>
                          <h5>{info.address}</h5>
                          <span>STREET ADDRESS</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
