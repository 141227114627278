import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
const slides = [
  "/images/banner-image-1-1920x300.jpg",
  "/images/blog-fullscreen-1-1920x700.jpg",
];

const Slider = () => {
  return (
    <Splide aria-label="My Favorite Images">
      {slides.map((img, i) => (
        <SplideSlide key={i}>
          <img src={img} alt="Image 1" className="img-fluid h-100 w-100" />
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default Slider;
