import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./Components/LoginPage";
import SignUpPageCustomer from "./Components/SignupPageCustomer";
import SignupType from "./Components/SignUpType";
import CarRenterDashboard from "./Components/CarRenterDashboard";
import CustomerDashboard from "./Components/CustomerDashboard";
import Booked from "./Components/Booked";
import LandingPage from "./Components/LandingPage";
import "./App.css";
import ProtectedComponent from "./Components/ProtectedComponent";
import Layout from "./Components/Layout";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="signup_type" element={<SignupType />} />
          <Route path="sign_up/:type" element={<SignUpPageCustomer />} />
          `{" "}
          <Route
            path="renter-dashboard"
            element={
              <ProtectedComponent onlyRenter={true}>
                <CarRenterDashboard />
              </ProtectedComponent>
            }
          />
          <Route
            path="customer-dashboard"
            element={
              <ProtectedComponent>
                <CustomerDashboard />
              </ProtectedComponent>
            }
          />
          <Route
            path="booked"
            element={
              <ProtectedComponent>
                <Booked />
              </ProtectedComponent>
            }
          />
        </Route>
        `
      </Routes>
    </>
  );
}

export default App;
