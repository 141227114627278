import API from "../base";

const register = async (data) => {
  return await API.postMethod(
    "https://skenderneziraj.com/api/user/register.php",
    false,
    data
  );
};

const addCar = async (data) => {
  return await API.postMethod(
    "https://skenderneziraj.com/api/car/addCar.php",
    true,
    data,
    false
  );
};
const deleteCar = async (data) => {
  return await API.postMethod(
    "https://skenderneziraj.com/api/car/deleteCar.php",
    true,
    data,
  );
};

const getCarByRenterId = async (id) => {
  return await API.getMethod(
    `https://skenderneziraj.com/api/car/getCarsbyRenterId.php?renter_id=${id}`,
    true
  );
};

const getCars = async (id) => {
  return await API.getMethod(
    `https://skenderneziraj.com/api/car/getCars.php`,
    true
  );
};

const addBooking = async (data) => {
  return await API.postMethod(
    `https://skenderneziraj.com/api/booking/addBooking.php`,
    true,
    data,
    true
  );
};
const updateBookingStatus = async (data) => {
  return await API.postMethod(
    `https://skenderneziraj.com/api/booking/updateBookingStatus.php`,
    true,
    data,
    true
  );
};

const getBookingByRenterId = async (id) => {
  return await API.getMethod(
    `https://skenderneziraj.com/api/booking/getAllBookingsByRenterId.php?renter_id=${id}`,
    true
  );
};

const getBookingByCustomerId = async (id) => {
  return await API.getMethod(
    `https://skenderneziraj.com/api/booking/getAllBookingsByCustomerId.php?customer_id=${id}`,
    true
  );
};

const getCarById = async (id) => {
  return await API.getMethod(
    `https://skenderneziraj.com/api/car/getCarbyId.php?car_id=${id}`,
    true
  );
};

const getCarModals = async () => {
  return await API.getMethod(`https://skenderneziraj.com/api/car/modals.php`);
};


const addCarRating = async (data) => {
  return await API.postMethod(`https://skenderneziraj.com/api/ratings/addRatings.php`,true,data);
};

const getCarByLocAndModal = async (modal='-1',city='',province='') => {
  return await API.getMethod(`https://skenderneziraj.com/api/car/getCarsByAreaAndModelName.php?model_name=${modal}&city=${city}&province=${province}`);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  register,
  addCar,
  getCarByRenterId,
  getCars,
  addBooking,
  getBookingByRenterId,
  getBookingByCustomerId,
  getCarById,
  getCarModals,
  updateBookingStatus,
  addCarRating,
  deleteCar,
  getCarByLocAndModal
};
