import React from "react";
import { Navigate } from "react-router-dom";
import { isLoggedIn, hasPlan } from "./auth";

const ProtectedComponent = ({ onlyRenter = false, children }) => {
  const loggedIn = isLoggedIn();
  const isRenter = hasPlan("basic");

  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

  if (onlyRenter && !isRenter) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default ProtectedComponent;
