import React, { useState, useCallback } from "react";
import SyncLoader from "react-spinners/SyncLoader";
import AuthAPIs from "../APIs/auth";
import toast from "react-hot-toast";
import { cities, province } from "../cities";

const Button = ({ onClick, children, disabled }) => (
  <button className={`rounded col ${ children === 'Get Location' ? "bg-primary" : "bg-warning" }`} style={{ height: '50px', borderRadius: "4px", border: "none" }} onClick={onClick} disabled={disabled}>
    {children}
  </button>
);

const Input = ({ id, type, placeholder, value, onChange }) => (
  <div className="col-md-6 mb-4">
    <div className="d-flex flex-column gap-1">
      <label htmlFor={id} className="text-warning">
        {placeholder}:
      </label>
      <input
      style={{ height:'50px', borderRadius:"4px", border:"none"}}
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-100"
      />
    </div>
  </div>
);
const BookingForm = ({ close, data }) => {
  const [fields, setFields] = useState({
    address: "",
    famousPlace: "",
    latitude: null,
    longitude: null,
    city: "",
    phone: "",
    province: "",
    reason_to_buy: "",
    startDate:"",
    endDate:""
  });

  const [gettingLocation, setGettingLocation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (field) => (event) => {
    setFields({ ...fields, [field]: event.target.value });
  };

  const handleSubmit = async (event) => {

    try {
      event.preventDefault();

      if(!validateForm()){
        return;
      }

      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      if (data) {
        const formData = new FormData();
        formData.append("car_id", data?.car_id);
        formData.append("customer_id", user?.id);
        formData.append("renter_id", data?.renter_id);
        formData.append("address", fields.address);
        formData.append("city", fields.city);
        formData.append("province", fields.province);
        formData.append("famous_place_nearby", fields.famousPlace);
        formData.append("start_date", fields.startDate);
        formData.append("end_date", fields.endDate);
        formData.append("latitude", fields.latitude);
        formData.append("longitude", fields.longitude);
        formData.append("reason_to_buy", fields.reason_to_buy);
        formData.append("customer_phone_number", fields.phone);

        const res = await AuthAPIs.addBooking(formData);
        if (res) {
          toast.success("Booking Successfull");
          close();
        }
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Error in Adding Booking");
      setIsLoading(false);
    }
  };

  const fetchLocation = (event) => {
    event.preventDefault();
    setGettingLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          toast.success(
            `Location Saved latitude: ${latitude}, longitude: ${longitude}`
          );
          setFields({ ...fields, longitude: longitude, latitude: latitude });
          setGettingLocation(false);
        },
        (error) => {
          toast.error("Error fetching location", error);
        }
      );
    } else {
      toast.log("Geolocation is not supported by this browser.");
    }
    setGettingLocation(false)
  };

  const validateForm = useCallback(() => {
    const requiredFields = [
      "address",
      "famousPlace",
      "reason_to_buy",
      "city",
      "phone",
      "province",
      "startDate",
      "endDate",
      "longitude",
      "latitude"
    ];
    for (const field of requiredFields) {
      if (!fields[field]) {
        toast.error(`${field} is missing`);
        return false;
      }
    }
    if(fields['endDate'] < fields['startDate']){
      toast.error("End Date Can't be less than Start Date");
      return false;
    }
    return true;
  }, [fields]);

  

  return (
    <div className="container mt-5 p-4" style={{ backgroundColor: "#f3f4f6" }}>
      <form className="" onSubmit={handleSubmit}>
        <div className="row">
         
          <div className="col-md-6 d-flex flex-column gap-1">
            <label className="text-warning" htmlFor="province">
              Province:
            </label>
            <select
              id="province"
              required
              className="w-100 select-form"
              value={fields.province}
              onChange={handleInputChange("province")}
            >
              <option value="" disabled>
                Select a Province
              </option>
              {province?.map((pro, i) => (
                <option key={i} value={pro}>
                  {pro}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6 d-flex flex-column gap-1">
            <label className="text-warning" htmlFor="city">
              City:
            </label>
            <select
              id="city"
              required
              className="w-100 select-form"
              value={fields.city}
              onChange={handleInputChange("city")}
            >
              {fields.province === "" ? (
                <option value="" disabled>
                  Select a Province First
                </option>
              ) : (
                <>
                  <option value="" disabled>
                    Select a City
                  </option>
                  {cities[fields.province]?.map((city, i) => (
                    <option key={i} value={city}>
                      {city}
                    </option>
                  ))}
                </>
              )}

            </select>
          </div>
          <Input
            id="address"
            type="text"
            placeholder="Address"
            value={fields.address}
            onChange={handleInputChange("address")}
          />
          <Input
            id="phone"
            type="tel"
            placeholder="Phone"
            value={fields.phone}
            onChange={handleInputChange("phone")}
          />
          <Input
            id="famousPlace"
            type="text"
            placeholder="Famous Place"
            value={fields.famousPlace}
            onChange={handleInputChange("famousPlace")}
          />
          <Input
            id="reason_to_buy"
            type="text"
            placeholder="Reason to Buy"
            value={fields.reason_to_buy}
            onChange={handleInputChange("reason_to_buy")}
          />
          <Input
            id="startDate"
            type="date"
            placeholder="Start Date"
            value={fields.startDate}
            onChange={handleInputChange("startDate")}
          />
          <Input
            id="endDate"
            type="date"
            placeholder="End Date"
            value={fields.endDate}
            onChange={handleInputChange("endDate")}
          />
        </div>
        <div className="row my-4 ">
          <Button onClick={fetchLocation}>
            {gettingLocation ? <SyncLoader color="#0ea5e9" /> : "Get Location"}
          </Button>
        </div>
        <div className="row gap-2">
          <Button onClick={() => close()} style={{ marginTop: "20px" }}>
            Cancel
          </Button>
          <Button
            type="submit"
            style={{ marginTop: "20px" }}
          >
            {isLoading ? <SyncLoader color="#0ea5e9" /> : "Save"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BookingForm;
