import React from "react";
import Footer from "./Footer";
import { Toaster } from "react-hot-toast";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
const Layout = () => {
  return (
    <div
      className="d-flex flex-column min-vh-100"
      style={{ backgroundColor: "#fafafa" }}
    >
      <Navbar />
      <div className="flex-grow-1">
        <Outlet />
      </div>
      <Footer />
      <Toaster />
    </div>
  );
};

export default Layout;
