import React, { useEffect, useState } from "react";
import CarForm from "./CarForm";
import AuthAPIs from "../APIs/auth";
import SyncLoader from "react-spinners/SyncLoader";

import CarGrid from "./CarGrid";
import CategoryHeader from "./CategoryHeader";
import toast from "react-hot-toast";

const CarRenterDashboard = () => {
  const [cars, setCars] = useState([]);
  const [filterCars,setFilterCars] = useState([])
  const [isAddingCar, setIsAddingCar] = useState(false);
  // const [selectedImage, setSelectedImage] = useState(null);
  const [APIimage1, setAPIimage1] = useState();
  const [APIimage2, setAPIimage2] = useState();
  const [APIimage3, setAPIimage3] = useState();
  const [APIimage4, setAPIimage4] = useState();
  const [isLoading, setIsLoading] = useState();
  const [carModals, setCarModals] = useState([]);

  useEffect(() => {
    getCars();
    getCarModals();
  }, []);

  const getCars = async () => {
    setIsLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        const res = await AuthAPIs.getCarByRenterId(user.id);
        if (res) {
          setCars(res.data?.cars);
          setFilterCars(res.data?.cars);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getCarModals = async () => {
    try {
      const res = await AuthAPIs.getCarModals();
      if (res) {
        setCarModals(res.data?.carModels);
      }
    } catch (error) {
      toast.error("Error while fetching Cars. Modals")
    }
  };

  const handleAddCarButtonClick = () => {
    setIsAddingCar((prev) => !prev);
  };

  const handleCarFormSave = async (car) => {

    const user = JSON.parse(localStorage.getItem("user"));


    try {
      const data = new FormData();
      data.append("renter_id", user?.id);
      data.append("company_id", car?.company_id);
      data.append("car_name", car?.car_name);
      data.append("color", car?.color);
      data.append("mileage", car?.mileage);
      data.append("engine", car?.engine);
      data.append("fuel_type", car?.fuelType);
      data.append("kms_driven", car?.kmsDriven);
      data.append("address", car?.address);
      data.append("latitude", car?.latitude);
      data.append("longitude", car?.longitude);
      data.append("famous_place_nearby", car?.famousPlace);
      data.append("city", car?.city);
      data.append("province", car?.province);
      data.append("price", car?.pricePerDay);
      data.append("phone_number", car?.phone);
      data.append("company_name", car?.company_name);
      data.append("image1", APIimage1);
      data.append("image2", APIimage2);
      data.append("image3", APIimage3);
      data.append("image3", APIimage4);

      const res = await AuthAPIs.addCar(data);
      if (res) {
        toast.success("Car Added Successfully");
        getCars();
      }
      setIsAddingCar(false);
    } catch (e) {
      toast.error("Error in Adding Car =", e);
    }
  };

  // const handleImageClick = (image) => {
  //   setSelectedImage(image);
  // };

  const handleCategoryClick = (category) => {
    if (typeof category === "string") {
      if(category !== -1){

        let filter = cars.filter((car)=> car.company_id === category)
        setFilterCars(filter)
        } 
        else{
        setFilterCars(cars)

       }

    }
  };

  // const handleCloseModal = () => {
  //   setSelectedImage(null);
  // };

  const handleDelete = async(car) => {

    const confirmed = window.confirm("Are you sure you want to delete this car?");
    if (!confirmed) {
     return
    }

    try {
      const formData = new FormData()
      formData.append('renter_id',car.renter_id)
      formData.append('car_id', car.car_id)

      let res = await AuthAPIs.deleteCar(formData)
      if(res){
        toast.success('Car Deleted Successfully')
        getCars()
      }
    } catch (error) {
      toast.error('Something went Wrong')
    }
  }

  return (
    <>
      <CategoryHeader handleCategoryClick={handleCategoryClick} cars={cars} />
      <div className="container my-4">
        <div className="float-right">
          <button
            className="bg-warning rounded btn text-light"
            onClick={handleAddCarButtonClick}
          >
            Add Car
          </button>
        </div>
      </div>

      {isAddingCar && (
        <CarForm
          carModals={carModals}
          onSave={handleCarFormSave}
          APIimage1={setAPIimage1}
          APIimage2={setAPIimage2}
          APIimage3={setAPIimage3}
          APIimage4={setAPIimage4}
        />
      )}

      {isLoading ? (
        <div style={{ marginLeft: "160px" }}>
          <SyncLoader color="#ffff01" />
        </div>
      ) : (
        <div className="container my-5">
            <CarGrid cars={filterCars} dash={true} handleDelete={handleDelete} />
        </div>
      )}

      {/* {selectedImage && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <img
              src={selectedImage}
              alt="Selected Car"
              className="modal-image"
              style={{ width: "200px", height: "200px" }}
            />
            <div className="status-text">Status:</div>
            <select className="status-dropdown">
              <option value="approve">Approve</option>
              <option value="confirm">Confirm</option>
              <option value="cancel">Cancel</option>
            </select>
          </div>
        </div>
      )} */}
    </>
  );
};

export default CarRenterDashboard;
