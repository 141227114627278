import React from "react";
import ReactStars from "react-rating-stars-component";
import Api from "../APIs/auth";
import toast from "react-hot-toast";
const BookingGrid = ({
  filteredBookings,
  handleImageClick,
  cus = true,
  handleActionClick,
}) => {


  const addRating = async (car,value) => {

    try {

      const formData = new FormData()
      formData.append('car_id',car.car_id)
      formData.append('customer_id', car.customer_id)
      formData.append('rating', value)

      let res = await Api.addCarRating(formData)
      if(res){
        handleActionClick(car.booking_id,'completed-rated')
        toast.success('Rating Added Successfully')
        return
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  }



  return (
    <div className="row my-3">
      {filteredBookings ? (
        filteredBookings.map((car, index) => (
          <div key={index} className="col-md-6 col-12 col-lg-4 mb-4 text-warning">
            <div className="card h-100">
              <div className="">
                {car?.image1 && (
                  <img
                    src={
                      `https://skenderneziraj.com/api/${car.image1}` ||
                      car.image1 || "./images/placeholder.jpeg"
                    }
                    alt={`Car ${index + 3}`}
                    className="card-img-top"
                    style={{ height: "250px", objectFit: "cover" }}
                    onClick={() => handleImageClick(car.image1)}
                  />
                )}
              </div>
              <div className="card-body text-capitalize text-start">
                {/* <h1 className="card-title text-warning">{car?.car_name}</h1> */}
                <p className="text-warning">
                  <span className="text-black">Car :</span> {car?.car.car_name}
                </p>
                <p className="text-warning">
                  <span className="text-black text-capitalize">Status :</span> {car?.status}
                </p>
                <p className="text-warning">
                  <span className="text-black">Address :</span> {car?.address}
                </p>
                <p className="text-warning">
                  <span className="text-black">Reason to Buy :</span>{" "}
                  {car?.reason_to_buy}
                </p>
                <p className="text-warning">
                  <span className="text-black"> Start Date :</span>{" "}
                  {car?.start_date}
                </p>
                <p className="text-warning">
                  <span className="text-black"> End Date :</span>{" "}
                  {car?.end_date}
                </p>
                <p className="text-warning">
                  <span className="text-black"> Phone :</span>{" "}
                  {car?.customer_phone_number}
                </p>
                <div className="row gap-2">
                  {cus ? (
                    // if customer
                    car.status === "pending" && (
                      <button
                        className="btn btn-danger col"
                        onClick={() =>
                          handleActionClick(car.booking_id, "Canceled")
                        }
                      >
                        Cancel
                      </button>
                    )
                  ) : (
                    <>
                    {/* if renter */}
                      {car.status === "pending" && (
                        <>
                            <button
                              className="btn btn-danger col"
                              onClick={() =>
                                handleActionClick(car.booking_id, "canceled")
                              }
            
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-warning col"
                              onClick={() =>
                                handleActionClick(car.booking_id, "active")
                              }
                            >
                              Active
                            </button>
                        </>
                      )}
                   
                      {car.status === "Active" && <button
                        className="btn btn-success col"
                        onClick={() =>
                          handleActionClick(car.booking_id, "completed")
                        }
                      >
                        Completed
                      </button>}
                    </>
                  )}
                  {cus && car.status === 'Completed' && (
                    <ReactStars
                      count={5}
                      value={Math.round((car?.car.ratings || 0) * 2) / 2}
                      onChange={(rating) =>addRating(car,rating)}
                      size={24}
                      isHalf={true}
                      edit={car.status !== 'completed-rated'}
                      activeColor="#ffc107"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No Bookings Available</p>
      )}
    </div>
  );
};

export default BookingGrid;
