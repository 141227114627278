import React, { useEffect, useState } from "react";

const CategoryHeader = ({ handleCategoryClick, cars }) => {
  const [company, setCompany] = useState([{ name: "All", id: -1 }]);
  const [selectedCompany, setSelectedCompany] = useState({ name: "All", id: -1 })
  useEffect(() => {
    if(cars){
      const companyNames = cars.map((car) => ({name:car.company_name,id:car.company_id}));
      const uniqueCompanyNames = companyNames.reduce((acc, current) => {
        const x = acc.find(item => item.name === current.name && item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      setCompany([{ name: "All", id: -1 }, ...uniqueCompanyNames]);
    }
  }, [cars]);

  const handleClick = (company) => {
    setSelectedCompany(company)
    handleCategoryClick(company.id)
  }


  return (
    <>
      <div className="container my-3 p-3">
        {/* <h1>Famous Co</h1> */}
          <div className="d-flex justify-content-center align-items-center gap-4 overflow-auto">
            {company.map((cat, i) => (
              <div key={i} onClick={() => handleClick(cat)} className="">
                <button className={`btn w-100 px-2 py-1 ${cat.id == selectedCompany.id ? 'btn-dark' : 'btn-warning'} `}>{cat.name}</button>
              </div>
            ))}
          </div>
      </div>
      <div className="sb sb-1">
        <hr className="section-break-1" />
      </div>
    </>
  );

};

export default CategoryHeader;
