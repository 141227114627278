import React, { useEffect, useState } from "react";
import AuthAPIs from "../APIs/auth";
import SyncLoader from "react-spinners/SyncLoader";

import BookingGrid from "./BookingGrid";
import toast from "react-hot-toast";
const Booked = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [role, setRole] = useState("cus");
  useEffect(() => {
    getBookingsByRenterOrCustomerId();
  }, []);

  const getBookingsByRenterOrCustomerId = async () => {
    setIsLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        // If Subscription plan is null it means the user is customer
        if (user?.subscriptionPlan === "") {
          setRole("cus");
          let APIArr;
          try {
            const customerBookings = await AuthAPIs.getBookingByCustomerId(
              user?.id
            );
            if (customerBookings) {
              var bookingArr = [];
              APIArr = customerBookings?.data?.bookings;
            }
          } catch (error) {
            toast.error("Something went wrong");
          }

          for (let i = 0; i < APIArr?.length; i++) {
            try {
              const car = await AuthAPIs?.getCarById(APIArr[i]?.car_id);
              if (car) {
                const obj = { ...APIArr[i] };
                obj["image1"] = car?.data?.data?.image1;
                obj["image2"] = car?.data?.data?.image2;
                obj["image3"] = car?.data?.data?.image3;
                obj["image4"] = car?.data?.data?.image4;
                obj['car'] = car?.data?.data

                bookingArr.push(obj);
              }
            } catch (error) {
            
              toast.error("Something Went Wrong!");
            }
          }

          setBookings(bookingArr);
          setFilteredBookings(bookingArr);
          setIsLoading(false);
        } else {
          setRole("renter");
          try {
            const renterBookings = await AuthAPIs.getBookingByRenterId(
              user?.id
            );
            if (renterBookings) {
              setBookings(renterBookings?.data?.bookings);
              setFilteredBookings(renterBookings?.data?.bookings);
            }
          } catch (error) {
            toast.error("Something Went Wrong!");
          }
        }
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setSelectedStatus(selectedStatus);
    const filtered =
      selectedStatus === "All"
        ? bookings
        : bookings.filter((booking) => booking.status === selectedStatus);
    setFilteredBookings(filtered);
  };

  const handleActionClick = async (id, status) => {
    const formData = new FormData();
    formData.append("booking_id", id);
    formData.append("status", status);
    try {
      const { data } = await AuthAPIs.updateBookingStatus(formData);
      if (data.status === 200) {
        toast.success("Status Succesfully Changed");
        getBookingsByRenterOrCustomerId();
      }
    } catch (error) {
     toast.error('Something Went Wrong!')
    }
  };

  return (
    <>
      <div className="container mt-5">
        <h1>Booked Cars</h1>
        <div className="d-flex justify-content-center flex-column">
          <label htmlFor="status-filter" className="text-warning">
            Filter by Status:
          </label>
          <select
            id="status-filter"
            className="form-select w-25"
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            <option value="All">All</option>
            <option value="pending">Pending</option>
            <option value="Canceled">Canceled</option>
            <option value="Active">Active</option>
            <option value="Completed">Completed</option>
            <option value="completed-rated">Completed Rated</option>
          </select>
        </div>
        {isLoading ? (
          <div style={{ marginLeft: "80px", marginTop: "30px" }}>
            <SyncLoader color="#ffc107" />
          </div>
        ) : (
          <>
            <BookingGrid
              filteredBookings={filteredBookings}
              handleImageClick={handleImageClick}
              cus={role === "cus"}
              handleActionClick={handleActionClick}
            />
          </>
        )}
      </div>
      {selectedImage && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <img
              src={selectedImage}
              alt="Selected Car"
              className="modal-image"
              style={{ width: "200px", height: "200px" }}
            />
            <div className="status-text">Status:</div>
            <select className="status-dropdown">
              <option value="approve">Approve</option>
              <option value="confirm">Confirm</option>
              <option value="cancel">Cancel</option>
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export default Booked;
