import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className=" pt-5 ">
      <footer
        className="text-center text-lg-start text-dark bg-warning"
        style={{ backgroundColor: "#d1d5db" }}
      >
        <div className="container p-4 pb-0">
          <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 col-lg-8 text-center text-md-start">
                <div className="p-3">
                  © 2020 Copyright:{" "}
                  <NavLink className="text-dark" to="/">
                    Car Connect
                  </NavLink>
                </div>
              </div>

              <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                <a
                  className="btn btn-outline-light btn-floating m-1 text-dark"
                  role="button"
                >
                  <i className="bi bi-facebook"></i>
                </a>

                <a
                  className="btn btn-outline-light btn-floating m-1 text-dark"
                  role="button"
                >
                  <i className="bi bi-twitter"></i>
                </a>

                <a
                  className="btn btn-outline-light btn-floating m-1 text-dark"
                  role="button"
                >
                  <i className="bi bi-google"></i>
                </a>

                <a
                  className="btn btn-outline-light btn-floating m-1 text-dark"
                  role="button"
                >
                  <i className="bi bi-instagram"></i>
                </a>
              </div>
            </div>
          </section>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
