// SignUpPageCustomer.jsx

import React from "react";
import backgroundImage from "./black car 2(mirror).jpg";
import { useNavigate } from "react-router-dom";

function SignupType() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        background: "#232323",
        color: "#fff",
        minHeight: "100vh",
      }}
    >
      <style>
        {`
          /* CSS styles */
          .login-signup-container {
            display: flex;
            width: 100vw;
            justify-content: space-between;
          }

          .form-container {
            width: 50%;
            max-width: 400px;
            padding: 0 20px;
            text-align: center;
          }

          .image-container {
            width: 50%;
            max-width: calc(100vw - 400px);
            overflow: hidden;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border: 1px solid #232323;
            background-color: #232323;
            padding: 20px;
          }

          .image-container img {
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }

          .form {
            padding: 20px;
            height: 100%; /* Set height to 100% */
            overflow-y: auto; /* Set overflow to scroll */
          }

          .form label {
            display: block;
            margin-bottom: 10px;
            font-weight: bold;
            text-align: left;
          }

          .form input, .form select {
            width: calc(100% - 20px);
            padding: 10px;
            margin-bottom: 20px;
            border: 1px solid #fff;
            border-radius: 5px;
          }

          .form button {
            width: calc(100% - 20px);
            padding: 10px;
            margin: 10px 0;
            background-color: #FFFF00;
            color: black;
            border: none;
            cursor: pointer;
          }

          .form button:hover {
            background-color: #FFFF00;
          }

          .form p {
            margin-top: 10px;
          }

          .form p button {
            background: none;
            border: none;
            color: #FFFF00;
            cursor: pointer;
          }

          .form p button:hover {
            text-decoration: underline;
          }

          h2 {
            font-size: 30px;
            font-weight: bold;
            color: #FFD059;
            margin-bottom: 20px;
            text-align: center;
          }

          .success-message {
            color: green;
            margin-top: 10px;
          }
          .error-message {
            color: red;
            margin-top: 10px;
          }

          .login-signup-container button,
          .login-signup-container h2 {
            position:relative;
            margin-top: 20px;
            z-index:2;
          }

          .login-signup-container .image-container img {
            position: static;
            height: 93vh;
          }
        `}
      </style>
      <div className="login-signup-container">
        <div className="form-container">
          <h2>Sign Up Type</h2>
          <div className="form">
            <button
              className="btn btn-warning"
              onClick={() => {
                navigate("/sign_up/renter");
              }}
            >
              Renter
            </button>
            <button
              className="btn btn-warning"
              onClick={() => {
                navigate("/sign_up/customer");
              }}
            >
              Customer
            </button>
          </div>
        </div>
        <div className="image-container">
          <img src={backgroundImage} alt="Background" />
        </div>
      </div>
    </div>
  );
}

export default SignupType;
