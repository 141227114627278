export const cities  = {
  "Ferizaj": [
    "Ferizaj",
    "Kaçanik",
    "Shtime",
    "Han i Elezit",
    "Shtërpcë"
  ],
  "Gjakovë": [
    "Gjakova",
    "Rahoveci",
    "Deçani",
    "Junik"
  ],
  "Gjilan": [
    "Gjilan",
    "Kamenica",
    "Klokot",
    "Parteshi",
    "Ranillug",
    "Viti"
  ],
  "Mitrovicë": [
    "Mitrovica",
    "Leposaviq",
    "Mitrovica Veriore",
    "Skenderaj",
    "Vushtrri",
    "Zubin Potok",
    "Zvećan"
  ],
  "Pejë": [
    "Peja",
    "Istog",
    "Klina"
  ],
  "Prishtinë": [
    "Prishtina",
    "Drenas",
    "Graçanica",
    "Fushë Kosova",
    "Lipjan",
    "Novobërdë",
    "Obiliq",
    "Podujeva"
  ],
  "Prizren": [
    "Prizren",
    "Dragash",
    "Malisheva",
    "Mamusha",
    "Suharekë"
  ]
}


export const province = [
  "Ferizaj",
  "Gjakovë",
  "Gjilan",
  "Mitrovicë",
  "Pejë",
  "Prishtinë",
  "Prizren"
]
