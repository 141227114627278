import React, { useState, useEffect } from "react";
import AuthAPIs from "../APIs/auth";
import SyncLoader from "react-spinners/SyncLoader";
import BookingForm from "./BookingForm";
import CarGrid from "./CarGrid";
import Contact from "./Contact";
import CategoryHeader from "./CategoryHeader";
import toast from "react-hot-toast";
import { cities, province } from "../cities";

// CustomerDashboard component
const CustomerDashboard = () => {
  const [cars, setCars] = useState([]);
  const [isBooking, setIsBooking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingdata, setBookingData] = useState();
  const [allCars,setAllCars] = useState([])
  const [fields, setFields] = useState({
    city:"",
    province:province[0],
    company:-1
  })

  // Function to handle category click
  const handleCategoryClick = (value) => {
    setFields({...fields, company: value})
    if(value === -1){
      setCars(allCars)
      return
    }
    let filterCars = allCars.filter(car => car.company_id === value)
    
    setCars(filterCars)

  }
  
  const handleInputChange = (field) => (event) => {
    setFields({ ...fields, [field]: event.target.value });
  };
  // Fetch car data from API on component mount
  // useEffect(() => {

  //    if (fields.city !== "" && fields.province !== "" && fields.company) {
  //      getCars();
  //    } else {
  //      toast.error('Select Location')
  //    }

  // }, [fields]);

  useEffect(()=>{
  if (fields.province) {
    let city = cities[fields.province]?.[0] || ""
    setFields((prevFields) => ({
      ...prevFields,
      city,
    }));
  }
  },[fields.province])

  useEffect(()=>{
    if (fields.city !== "" && fields.province !== ""){
      getAllCars();
    }
  },[fields.city])


  const getAllCars = async () => {
    setIsLoading(true);
    try {
      const res = await AuthAPIs.getCarByLocAndModal(-1, fields.city, fields.province);
      if (res) {
        let cars = res.data?.cars
        let nonBookedCars = cars.filter((car) => car.isBooked == 0)
        setCars(nonBookedCars)
        setAllCars(nonBookedCars);
      }
    } catch (error) {
      if (error?.response?.data?.message === "No record found") {
        setCars([])
        setAllCars([])
        toast.error("No Record Found")
        return
      }
      toast.error("Something went wrong!");
    }
    finally {

      setIsLoading(false);
    }
  };

  const getCars = async () => {
    setIsLoading(true);
    try {
      const res = await AuthAPIs.getCarByLocAndModal(fields.company, fields.city, fields.province);
      if (res) {
        let cars = res.data?.cars
        let nonBookedCars = cars.filter((car) => car.isBooked == 0)
        setCars(nonBookedCars);
      }
    } catch (error) {
      if (error?.response?.data?.message === "No record found"){
        setCars([])
        toast.error("No Record Found")
        return
      }
      toast.error("Something went wrong!");
    }finally{

      setIsLoading(false);
    }
  };

  const handleBookNow = (data) => {
    setBookingData(data);
    setIsBooking(true);
  };

  const closeBookingForm = () => {
    setBookingData(null);
    setIsBooking(false);
    getCars()
  };

  return (
    <>
      <CategoryHeader handleCategoryClick={handleCategoryClick} cars={allCars}/>
      {/* Car list or Booking Form */}
      {isBooking ? (
        <BookingForm close={closeBookingForm} data={bookingdata} />
      ) : (
        <div className="container my-5">
          <div>
            <h1 className="">Available Cars Near You</h1>

            <div className="row my-5">
              <h4 className="text-center">Filter based on Location</h4>
                <div className="col-md-6 d-flex flex-column gap-1">
                  <label className="text-warning" htmlFor="province">
                    Province:
                  </label>
                  <select
                    id="province"
                    required
                    className="w-100 select-form"
                    value={fields.province}
                    onChange={handleInputChange("province")}
                  >
                    <option value="" disabled>
                      Select a Province
                    </option>
                    {province?.map((pro, i) => (
                      <option key={i} value={pro}>
                        {pro}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 d-flex flex-column gap-1">
                  <label className="text-warning" htmlFor="city">
                    City:
                  </label>
                  <select
                    id="city"
                    required
                    className="w-100 select-form"
                    value={fields.city}
                    onChange={handleInputChange("city")}
                  >
                        {cities[fields.province]?.map((city, i) => (
                          <option key={i} value={city}>
                            {city}
                          </option>
                        ))}

                  </select>
                </div>
            </div>

          </div>
          {isLoading ? (
            <div style={{ marginLeft: "160px" }}>
              <SyncLoader color="#ffff01" />
            </div>
          ) : 
            <CarGrid cars={cars} dash={true} handleBook={handleBookNow} />
           }
        </div>
      )}
      <Contact />
    </>
  );
};

export default CustomerDashboard;
