const CarCard = ({ car }) => (
  <div className="col-md-4 mb-4 col-6">
    <div className="card h-100">
      <div style={{ height: "75%" }}>
        <img
          src={car.image}
          className="card-img-top h-100 w-100"
          alt={`${car.make} ${car.model}`}
        />
      </div>
      <div
        className="card-body "
        style={{
          backgroundColor: "#ffc107",
          // height: "",
          borderTop: "2px solid yellow",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title text-dark">
            {car.make} {car.model}
          </h5>
          <p className="card-text text-dark">Year: {car.year}</p>
        </div>
        <p className="card-text text-dark fs-6">{car.description}</p>


      </div>
    </div>
  </div>
);
const CarDetailCard = ({ car, handleBook, handleDelete }) => (
  <div className="col-md-6 col-12 col-lg-4 mb-4 ">
    <div className="card h-100">
      <div style={{ height: "75%" }}>
        <img
          src={`https://skenderneziraj.com/api/${car.image1}` || "./images/placeholder.jpeg"}
          className="card-img-top"
          style={{ height: "250px", objectFit: "cover" }}
          alt={`${car.make} ${car.model}`}
          onError={(e) => {
            e.target.src = "./images/placeholder.jpeg";
          }}
        />
      </div>
      <div
        className="card-body "
        style={{
          backgroundColor: "#ffc107",
          // height: "",
          borderTop: "2px solid yellow",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title text-dark">Name: {car.car_name}</h5>
          <p className="card-text text-dark">Color: {car.color}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="card-text text-dark">Mileage: {car.mileage}Km's</p>
          <p className="card-text text-dark">Color: {car.engine}cc</p>
        </div>
        <p className="card-text font-bold text-dark text-start">
          Price per day: {car.price}
        </p>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-info p-1 w-100 d-flex justify-content-center my-3"
            style={{ bottom: "10px", right: "10px" }}
            data-bs-toggle="modal"
            data-bs-target={`#carModal_${car.car_id}`}
          >
            Details
          </button>
        </div>
        {handleBook && (
          <div className="d-flex justify-content-end">
            <button
              className="btn p-1 w-100 btn-lg btn-dark text-light d-flex justify-content-center"
              onClick={() => handleBook(car)}
            >
              <span className="text-center fs-4">Book Now</span>
            </button>
          </div>
        )}
        {handleDelete && (<div className="d-flex justify-content-end">
          <button className="btn p-1 w-100 btn-lg bg-danger d-flex justify-content-center " style={{ height: '40px', borderStartEndRadius: "4px", border: 'none' }} onClick={() => handleDelete(car)}>
            Delete
          </button>
        </div>)
        }

      </div>
    </div>

    {/* Modal */}
    <div
      className="modal fade"
      id={`carModal_${car.car_id}`}
      tabIndex="-1"
      aria-labelledby={`carModalLabel_${car.car_id}`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`carModalLabel_${car.car_id}`}>
              {car.car_name} Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row ">
              {[car.image1, car.image2, car.image3, car.image4].map((image, index) =>
                image ? (
                  <div className="col-12 col-md-6" key={index}>
                    <img
                      src={`https://skenderneziraj.com/api/${image}`}
                      className="img-fluid mb-2"
                      alt={`${car.car_name} image ${index + 1}`}
                    />
                  </div>
                ) : null
              )}
            </div>
            <div className="row px-4">
              <p className="col-12 col-md-6"><strong>Company:</strong> {car.company_name}</p>
              <p className="col-12 col-md-6"><strong>Color:</strong> {car.color}</p>
              <p className="col-12 col-md-6"><strong>Mileage:</strong> {car.mileage} km</p>
              <p className="col-12 col-md-6"><strong>Engine:</strong> {car.engine} cc</p>
              <p className="col-12 col-md-6"><strong>Fuel Type:</strong> {car.fuel_type}</p>
              <p className="col-12 col-md-6"><strong>Kms Driven:</strong> {car.kms_driven} km</p>
              <p className="col-12 col-md-6"><strong>Address:</strong> {car.address}</p>
              <p className="col-12 col-md-6"><strong>Phone Number:</strong> {car.phone_number}</p>
              <p className="col-12 col-md-6"><strong>Famous Place Nearby:</strong> {car.famous_place_nearby}</p>
              <p className="col-12 col-md-6"><strong>City:</strong> {car.city}</p>
              <p className="col-12 col-md-6"><strong>Province:</strong> {car.province}</p>
              <p className="col-12 col-md-6"><strong>Ratings:</strong> {car.ratings}</p>
              <p className="col-12 col-md-6"><strong>Booked:</strong> {car.isBooked === "1" ? "Yes" : "No"}</p>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    {/* Modal end */}


  </div>
);

const CarGrid = ({ cars, dash = false, handleBook = null, handleDelete=null }) => {


  return (
    <div className="row">
      {cars.length > 0 ? !dash
        ? cars.map((car, i) => <CarCard key={i} car={car} />)
        : cars.map((car, i) => (
          <CarDetailCard key={i} car={car} handleBook={handleBook} handleDelete={handleDelete} />
        )) : <div>
        <h1 className="text-center text-warning">No Record Found</h1>
      </div> }
      
    </div>
  );
};

export default CarGrid;

// address: "g1 johar town";
// car_id: "90";
// car_name: "alto";
// city: "lahore";
// color: "black";
// company_id: "9";
// company_name: "Grand Carnival";
// engine: "1500";
// famous_place_nearby: "doctors hospital";
// fuel_type: "petrol";
// image1: "uploads/cars/renter_id_11/1721063785att_66552.png";
// image2: "uploads/cars/renter_id_11/1721063785att_66552.png";
// image3: "uploads/cars/renter_id_11/1721063785att_66552.png";
// image4: "uploads/cars/renter_id_11/1721063785att_66552.png";
// isBooked: "0";
// kms_driven: "12900";
// latitude: "-31.9485";
// longitude: "-71.3478";
// mileage: "15.7";
// phone_number: "0300047856";
// price: "1200000";
// province: "punjab";
// ratings: "-1";
// renter_id: "11";
