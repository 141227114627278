import React from "react";

import Service from "./Service";
import cars from "../data.json";
import Contact from "./Contact";
import CarGrid from "./CarGrid";
import Slider from "./Slider";

const LandingPage = () => {
  return (
    <>
      <Slider />
      <Service />
      <div className="sb sb-1">
        <hr className="section-break-1" />
      </div>
      <div className="container">
        <div className="text-center mb-5">
          <h1>Car Fleet</h1>
          <p className="text-dark">Explor our Car Collections.</p>
        </div>

        <div className="container my-5">
          <CarGrid cars={cars} />
        </div>
      </div>
      <div className="sb sb-1">
        <hr className="section-break-1" />
      </div>
      <Contact />
    </>
  );
};

export default LandingPage;
