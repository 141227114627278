import React from "react";

import "bootstrap-icons/font/bootstrap-icons.css";
const whyMe = [
  {
    icon: "bi-tags-fill",
    title: "More for Less",
    description:
      "We offer e-tickets with exceptional discounted deals across the country.",
  },
  {
    icon: "bi-currency-dollar",
    title: "Lowest Fares",
    description: "We provide affordable tickets to save up to 50%.",
  },
  {
    icon: "bi-compass-fill",
    title: "Discover",
    description:
      "We make travelling easy across Pakistan by providing easy e-ticket.",
  },
];

const Service = () => {
  return (
    <div className="container py-5 text-dark">
      <div className="text-center mb-5">
        <h2>Why Use Car Connect?</h2>
        <p className="text-dark">We provide the best deals.</p>
      </div>
      <div className="row justify-content-center">
        {whyMe.map((el, index) => (
          <div className="col-md-4 mb-4" key={index}>
            <div
              className="card p-4"
              style={{ height: "100%", border: "1px solid #ffc107" }}
            >
              <div className="icon mb-3 text-center">
                <i
                  className={`bi ${el.icon} text-warning`}
                  style={{ fontSize: "2rem" }}
                ></i>
              </div>
              <h4 className="text-center">{el.title}</h4>
              <p className="text-center text-dark">{el.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
