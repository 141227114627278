import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAuth, setIsAuth] = useState(null);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const nav = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Booking",
      path: "/booked",
    },
    {
      name: "Dashboard",
      path: isAuth
        ? isAuth === "customer"
          ? "/customer-dashboard"
          : "/renter-dashboard"
        : "/customer-dashboard",
    },
    {
      name: "Contact",
      path: "/",
    },
  ];

  useEffect(() => {
    if (user) {
      if (user?.subscriptionPlan === "") {
        setIsAuth("customer");
      } else {
        setIsAuth("renter");
      }
    }
  }, [user]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setIsAuth(null);
    navigate("/");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-warning">
      <div className="container">
        <NavLink
          className="navbar-brand fs-3 d-flex justify-content-center align-content-center"
          to="/"
        >
          <img src="./icon.png" alt="car connect" width={"70px"} />
          <span>Car Connect</span>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarSupportedContent"
          aria-expanded={isOpen ? "true" : "false"}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto">
            {nav.map(({ name, path }, index) => (
              <li className="nav-item" key={index}>
                <NavLink
                  className="nav-link"
                  to={path}
                  onClick={() => setIsOpen(false)} // Close the navbar on click
                >
                  {name}
                </NavLink>
              </li>
            ))}
          </ul>
          {isAuth ? (
            <button
              onClick={handleLogout}
              className="btn btn-dark text-light my-2"
            >
              Logout
            </button>
          ) : (
            <button
              onClick={() => navigate("/login")}
              className="btn btn-dark text-light my-2"
            >
              Login
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
